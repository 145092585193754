import Cache from '@aws-amplify/cache'
import { Row, Col, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'

import gameDemo from '../assets/videos/gamedemo.mp4'
import step1 from '../assets/img/step_1.svg'
import step2 from '../assets/img/step_2.svg'
import step3 from '../assets/img/step_3.svg'
import step4 from '../assets/img/step_4.svg'
import step5 from '../assets/img/step_5.svg'
import Layout from '../components/Layout'
import IndexCTAButton from '../components/IndexBanner/IndexCTAButton'

const industryCacheKey = 'rsk-web.industry'

const HowItWorks = () => {
  const [industry, setIndustry] = useState('health')

  useEffect(() => {
    setIndustry(Cache.getItem(industryCacheKey) || 'health')
  }, [setIndustry])

  const hrStyle = {
    border: 'none',
    width: '100px',
    height: '2px',
    backgroundColor: '#333',
  }

  const hrStyle2 = {
    border: 'none',
    width: '200px',
    height: '2px',
    backgroundColor: '#333',
  }

  const imgStyle = { height: 100 }

  const CTAStyle = {
    border: '2px solid #4e4e4e',
    borderRadius: '50px',
    backgroundColor: '#4e4e4e',
    boxShadow: '15px 10px #e5e5e5',
    color: 'white',
    cursor: 'pointer',
    fontFamily: 'PT Sans',
    fontWeight: 'bold',
    fontSize: '20px',
    height: '80px',
    width: '200px',
    outline: 'none',
  }

  return (
    <Layout selectedMenuKey="howitworks">
      <Row type="flex" justify="center my3">
        <Col xs={16} md={8} className="center mt2">
          <Typography.Title level={3}>
            <FormattedMessage id="page.howitworks.title" />
          </Typography.Title>
          <hr style={hrStyle} />
        </Col>
      </Row>
      <Row type="flex" justify="center mt3">
        <Col xs={16} md={4} className="center mx4">
          <img src={step1} alt="Step1" style={imgStyle} />
          <Typography.Paragraph className="m2">
            <FormattedMessage id="page.howitworks.step1" />
          </Typography.Paragraph>
        </Col>
        <Col xs={16} md={4} className="center mx4">
          <img src={step2} alt="Step2" style={imgStyle} />
          <Typography.Paragraph className="m2">
            <FormattedMessage id="page.howitworks.step2" />
          </Typography.Paragraph>
        </Col>
        <Col xs={16} md={4} className="center mx4">
          <img src={step3} alt="Step3" style={imgStyle} />
          <Typography.Paragraph className="m2">
            <FormattedMessage id="page.howitworks.step3" />
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col xs={16} md={4} className="center mx4">
          <img src={step4} alt="Step4" style={imgStyle} />
          <Typography.Paragraph className="m2">
            <FormattedMessage id="page.howitworks.step4" />
          </Typography.Paragraph>
        </Col>
        <Col xs={16} md={4} className="center mx4">
          <img src={step5} alt="Step5" style={imgStyle} />
          <Typography.Paragraph className="m2">
            <FormattedMessage id="page.howitworks.step5" />
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row type="flex" justify="center my3">
        <Col xs={16} md={16} className="center mt2">
          <Typography.Title level={3}>
            <FormattedMessage id="page.howitworks.numbers-challenge" />
          </Typography.Title>
          <hr style={hrStyle2} />
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col xs={18} md={12} className="center">
          <ReactPlayer
            url={gameDemo}
            controls
            width="100%"
            height="auto"
            className="react-player"
            style={{ border: '1px solid #ddd' }}
          />
        </Col>
      </Row>
      <Row justify="center" type="flex" className="mt4">
        <Col md={12} xs={18} className="center">
          <Link to={`/${industry}`} state={{ industry }}>
            <IndexCTAButton style={CTAStyle} />
          </Link>
        </Col>
      </Row>
      <Row justify="center" type="flex" className="my3">
        <Col md={10} xs={12} className="center">
          <Typography.Text>
            <FormattedMessage id="page.howitworks.description" />
          </Typography.Text>
          <hr style={hrStyle2} />
        </Col>
      </Row>
    </Layout>
  )
}

export default HowItWorks
