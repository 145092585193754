import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const IndexCTAButton = ({ style }) => (
  <button style={style} type="button" className="h3">
    <FormattedMessage id="comp.indexbanner.cta-buton.name" />
  </button>
)

IndexCTAButton.propTypes = {
  style: PropTypes.object,
}

IndexCTAButton.defaultProps = {
  style: {},
}

export default IndexCTAButton
